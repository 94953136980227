<template>
  <BaseDialog title="編輯審查資訊" width="600px" @close="$emit('close')" @confirm="onConfirm">
    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <el-form-item label="店家名稱" prop="name">
        <el-input v-model="formData.name" placeholder="請輸入" />
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input v-model="formData.address" placeholder="請輸入" />
      </el-form-item>
      <el-form-item label="電話" prop="phone">
        <el-input v-model="formData.phone" placeholder="請輸入" />
      </el-form-item>
      <el-form-item label="統編" prop="shopIdentifier">
        <el-input v-model="formData.shopIdentifier" placeholder="請輸入" />
      </el-form-item>
    </el-form>
  </BaseDialog>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, computed } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { UpdateMemberShopPublicPageConfig } from '@/api/memberShop'
import { noEmptyRules, phoneRules, isDigitRules, maxRules } from '@/validation'
import formUtils from '@/utils/form'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'MemberShopPublicPageConfigEditModal',
  components: { BaseDialog },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  emits: ['close', 'refresh'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const loading = ref(false)
    const formRef = ref(null)
    const formData = reactive({
      name: null,
      address: null,
      phone: null,
      shopIdentifier: null,
    })
    const formRules = {
      name: [noEmptyRules()],
      address: [noEmptyRules()],
      phone: [noEmptyRules(), phoneRules(true, true, true)],
      shopIdentifier: [noEmptyRules(), isDigitRules(), maxRules(8)],
    }

    const compactPostData = computed(() => {
      return {
        shopId: shopId.value,
        ...formData,
      }
    })

    const updateMemberShopPublicPageConfig = async (payload) => {
      const [, err] = await UpdateMemberShopPublicPageConfig(payload)
      if (err) {
        window.$message.error(err)
        return err
      }
      window.$message.success('更新成功！')
    }

    const syncData = () => {
      const config = props.configData
      formData.name = config.name
      formData.address = config.address
      formData.phone = config.phone
      formData.shopIdentifier = config.shopIdentifier
    }

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const formPass = await formUtils.checkForm(formRef.value)
      if (!formPass) {
        loading.value = false
        return
      }
      const error = await updateMemberShopPublicPageConfig(compactPostData.value)
      loading.value = false
      if (error) return
      emit('refresh')
      emit('close')
    }

    onMounted(() => {
      syncData()
    })

    return { formRef, formData, formRules, onConfirm }
  },
})
</script>

<style lang="postcss" scoped>

</style>
