<template>
  <div class="member-shop-invoice-settings">
    <PageTitle
      title="審查公開頁設定"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({ name: 'MemberShopParameterSetting' })"
    />
    <MemberShopPublicPageConfigBlock
      v-loading="loading"
      :configData="publicPageConfigData"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, computed } from 'vue'
import { GetMemberShopPublicPageConfig } from '@/api/memberShop'
import PageTitle from '@/components/Title/PageTitle.vue'
import MemberShopPublicPageConfigBlock from './MemberShopPublicPageConfigBlock.vue'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'MemberShopPublicPageSetting',
  components: { PageTitle, MemberShopPublicPageConfigBlock },
  setup (props) {
    const { shopId } = useShop()
    const loading = ref(false)
    const publicPageConfigData = ref({})

    const getPublicPageConfig = async () => {
      const [res, err] = await GetMemberShopPublicPageConfig({ shopId: shopId.value })
      if (err) window.$message.error(err)
      publicPageConfigData.value = res
    }

    const refresh = async () => {
      if (loading.value) return
      loading.value = true
      await getPublicPageConfig()
      loading.value = false
    }

    onMounted(async () => {
      await refresh()
    })
    return { refresh, publicPageConfigData, loading }
  },
})
</script>

<style scoped lang="postcss">

</style>
